"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ROUTES = void 0;
exports.ROUTES = [
    {
        source: "/",
        destination: "/root",
        currentNavbar: "default",
        currentFooter: "default",
    },
    {
        source: "/favoritter",
        destination: "/favorites",
        currentNavbar: "default",
        currentFooter: "default",
    },
    {
        source: "/produkter",
        destination: "/products",
        currentNavbar: "default",
        currentFooter: "default",
    },
    {
        source: "/kategorier/:categoryId/:categoryName",
        destination: "/categories/:categoryId/:categoryName",
        currentNavbar: "default",
        currentFooter: "default",
    },
    {
        source: "/produkter/:productId",
        destination: "/products/:productId",
        currentNavbar: "default",
        currentFooter: "default",
    },
    {
        source: "/kategorier/:categoryId/:categoryName/:subCategoryId/:subCategoryName",
        destination: "/categories/:categoryId/:categoryName/:subCategoryId/:subCategoryName",
        currentNavbar: "default",
        currentFooter: "default",
    },
    {
        source: "/udforsk",
        destination: "/explore",
        currentNavbar: "default",
        currentFooter: "default",
    },
    {
        source: "/butikker",
        destination: "/stores",
        currentNavbar: "default",
        currentFooter: "default",
    },
    {
        source: "/butikker/:id",
        destination: "/stores/:id",
        currentNavbar: "default",
        currentFooter: "default",
    },
    {
        source: "/butikker/:id/:name",
        destination: "/stores/:id/:name",
        currentNavbar: "default",
        currentFooter: "default",
    },
    {
        source: "/kurv",
        destination: "/cart",
        currentNavbar: "default",
        currentFooter: "default",
    },
    {
        source: "/kurv/kvittering",
        destination: "/cart/receipt",
        currentNavbar: "default",
        currentFooter: "default",
    },
    {
        source: "/handelsbetingelser",
        destination: "/terms-and-conditions",
        currentNavbar: "default",
        currentFooter: "default",
    },
    {
        source: "/om-os",
        destination: "/about-us",
        currentNavbar: "default",
        currentFooter: "default",
    },
    {
        source: "/privatlivsbetingelser",
        destination: "/privacy-policy",
        currentNavbar: "default",
        currentFooter: "default",
    },
    {
        source: "/cookies",
        destination: "/cookies",
        currentNavbar: "default",
        currentFooter: "default",
    },
    {
        source: "/abonnementsbetingelser",
        destination: "/subscription-terms-and-conditions",
        currentNavbar: "default",
        currentFooter: "default",
    },
    {
        source: "/kundeservice",
        destination: "/customer-service",
        currentNavbar: "default",
        currentFooter: "default",
    },
    {
        source: "/bliv-saelger",
        destination: "/become-seller",
        currentNavbar: "default",
        currentFooter: "default",
    },
    {
        source: "/baglokalet/legeplads",
        destination: "/backroom/playground",
        currentNavbar: "backroom",
        currentFooter: "default",
    },
    {
        source: "/baglokalet/trin-for-trin-legeplads",
        destination: "/backroom/stepper-playground",
        currentNavbar: "default",
        currentFooter: "default",
    },
    {
        source: "/baglokalet/login",
        destination: "/backroom/login",
        currentNavbar: "none",
        currentFooter: "none",
        fullScreen: false,
    },
    {
        source: "/baglokalet/opret-bruger/:storeId",
        destination: "/backroom/register/:storeId",
        currentNavbar: "none",
        currentFooter: "none",
        fullScreen: true,
    },
    {
        source: "/baglokalet/produkter",
        destination: "/backroom/products",
        currentNavbar: "backroom",
        currentFooter: "none",
        fullScreen: false,
    },
    {
        source: "/baglokalet/produkter/opret",
        destination: "/backroom/products/new",
        currentNavbar: "backroom",
        currentFooter: "none",
        fullScreen: true,
    },
    {
        source: "/baglokalet/produkter/:id/rediger",
        destination: "/backroom/products/:id/edit",
        currentNavbar: "backroom",
        currentFooter: "none",
        fullScreen: true,
    },
    {
        source: "/baglokalet/ordrer",
        destination: "/backroom/orders",
        currentNavbar: "backroom",
        currentFooter: "none",
        fullScreen: false,
    },
    {
        source: "/baglokalet/transaktioner",
        destination: "/backroom/revenue",
        currentNavbar: "backroom",
        currentFooter: "none",
        fullScreen: false,
    },
    {
        source: "/baglokalet/butik",
        destination: "/backroom/store",
        currentNavbar: "backroom",
        currentFooter: "none",
        fullScreen: false,
    },
    {
        source: "/baglokalet/butik/opret",
        destination: "/backroom/store/new",
        currentNavbar: "none",
        currentFooter: "none",
        fullScreen: false,
    },
    {
        source: "/baglokalet/butik/rediger",
        destination: "/backroom/store/edit",
        currentNavbar: "backroom",
        currentFooter: "none",
        fullScreen: false,
    },
    {
        source: "/baglokalet/butik/opret/kvittering",
        destination: "/backroom/store/new/receipt",
        currentNavbar: "none",
        currentFooter: "none",
        fullScreen: true,
    },
    {
        source: "/baglokalet/logud",
        destination: "/backroom/logout",
        currentNavbar: "none",
        currentFooter: "none",
        fullScreen: true,
    },
    {
        source: "/baglokalet/glemt-adgangskode",
        destination: "/backroom/forgot-password",
        currentNavbar: "none",
        currentFooter: "default",
        fullScreen: false,
    },
    {
        source: "/baglokalet/produkter/:id",
        destination: "/backroom/products/:id",
        currentNavbar: "backroom",
        currentFooter: "default",
        fullScreen: false,
    },
    {
        source: "/baglokalet/vilkaar-og-betingelser",
        destination: "/backroom/terms-and-conditions",
        currentNavbar: "default",
        currentFooter: "default",
    },
    {
        source: "/baglokalet/butikskategorier",
        destination: "/backroom/product-tags",
        currentNavbar: "backroom",
        currentFooter: "default",
    },
    {
        source: "/baglokalet/butikskategorier/opret",
        destination: "/backroom/product-tags/new",
        currentNavbar: "backroom",
        currentFooter: "none",
        fullScreen: true,
    },
    {
        source: "/baglokalet/butikskategorier/:id/rediger",
        destination: "/backroom/product-tags/:id/edit",
        currentNavbar: "backroom",
        currentFooter: "none",
        fullScreen: true,
    },
    {
        source: "/baglokalet/omsaetning",
        destination: "/backroom/revenue",
        currentNavbar: "backroom",
        currentFooter: "none",
        fullScreen: true,
    },
];
